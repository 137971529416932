/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:49:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-10-Sa 03:16:26
 */
<template>
  <div class="teacherInformation">
    <div class="teacher_contop">
      <div class="width_1200">
        <div class="teacherpic_box">
          <div class="headImg">
            <img :src="teacherInfo.HeadImg" class="headImg" alt />
            <a
              :href="'/art/artistRegister?UserID=' + teacherInfo.StudentId"
              class="edit"
            >
              <i class="el-icon-edit-outline"></i>
            </a>
          </div>
          <div class="teacherinfo">
            <p>
              {{ teacherInfo.SupplierName }}
              <img
                src="@/assets/images/icon_qian.png"
                alt=""
                v-if="teacherInfo.IsSign == 1"
                class="icon_qian"
                srcset=""
              />
            </p>
          </div>
          <div>
            <p class="auth" v-if="teacherInfo.Status == 1">
              <span>已入驻</span>
            </p>
            <p class="auth" v-if="teacherInfo.Status == 0">
              <span style="background: #eb6709">审核中</span>
            </p>
            <p class="auth" v-if="teacherInfo.Status == -1">
              <span style="background: #ff4c52">未通过</span>
            </p>
          </div>
        </div>
      </div>
      <div class="cont">
        <div class="cont_top width_1200">
          <div class="width_1200">
            <span
              :class="titlesIndex == i ? 'active' : ''"
              v-for="(ti, i) in titlesOne"
              :key="i"
              @click="titlesClickOne(i)"
              >{{ ti }}</span
            >
          </div>
        </div>
        <div class="cont_lists bgc_f5f5f5">
          <div class="width_1200">
            <div id="introduction">
              <div class="title_dom">简介</div>
              <div
                class="descript"
                v-if="teacherInfo.Remark"
                v-html="teacherInfo.Remark"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="scrollProd">
              <div class="title_dom">作品</div>
              <div v-if="artList.length > 0">
                <ul class="cont_li">
                  <li
                    class="hover_shadow"
                    v-for="(item, index) in artList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/art/artistsDetails',
                        query: {
                          id: item.ArtId,
                          artUserId: teacherInfo.StudentId,
                        },
                      }"
                    >
                      <img
                        v-lazy="item.ArtImg"
                        class="coverImg"
                        alt=""
                        srcset=""
                      />
                      <p class="ovdecp" style="padding: 0 15px">
                        {{ item.ArtTitle }}
                      </p>
                    </router-link>
                  </li>
                </ul>
                <div class="width_1200">
                  <el-pagination
                    background
                    @size-change="handleSizeChangeProd"
                    @current-change="handleCurrentChangeProd"
                    :current-page.sync="page"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="totalProd"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="iLikeIt">
              <div class="title_dom">我赞</div>
              <div v-if="recList.length > 0">
                <ul class="cont_li">
                  <li
                    class="hover_shadow"
                    v-for="(item, index) in recList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/art/artistsDetails',
                        query: {
                          id: item.ArtId,
                          artUserId: teacherInfo.StudentId,
                        },
                      }"
                    >
                      <img
                        v-lazy="item.ArtImg"
                        class="coverImg"
                        alt=""
                        srcset=""
                      />
                      <p class="ovdecp" style="padding: 0 15px">
                        {{ item.ArtTitle }}
                      </p>
                      <div class="default">
                        <p
                          class="defaultPrice"
                          v-if="item.DefaultPrice != null"
                        >
                          <span>￥</span>{{ item.DefaultPrice }}
                        </p>
                        <p v-if="item.CollectCount > 0">
                          <img
                            src="@/assets/images/icon_heart_3.png"
                            alt=""
                            class="icon_heart"
                          />
                          {{ item.CollectCount }}
                        </p>
                      </div>
                    </router-link>
                  </li>
                </ul>
                <div class="width_1200">
                  <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageIndex"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="exhibitionExperience">
              <div class="title_dom">参展经历</div>
              <div
                class="descript"
                v-if="teacherInfo.ExhibitionExperience"
                v-html="teacherInfo.ExhibitionExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="awardWinningExperience">
              <div class="title_dom">获奖经历</div>
              <div
                class="descript"
                v-if="teacherInfo.AwardExperience"
                v-html="teacherInfo.AwardExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="publishingExperience">
              <div class="title_dom">出版经历</div>
              <div
                class="descript"
                v-if="teacherInfo.PublishingExperience"
                v-html="teacherInfo.PublishingExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="auctionRecord">
              <div class="title_dom">拍卖记录</div>
              <div
                class="descript"
                v-if="teacherInfo.AuctionExperience"
                v-html="teacherInfo.AuctionExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryTeacherInfo, commonQueryTeacherProduce, courseQueryCourseList } from "@/api/home";
import { queryArtistDetail, queryArtistProduce } from "@/api/fineArts";
import { querySupplierById, queryNewestArtExhibition, queryMyCollectArt } from "@/api/art";
import noData from "@/components/noData";
export default {
  data () {
    return {
      teacherInfo: {},
      artList: [],
      recList: [],
      titles: ["简介", "讲师作品", "直播课程", "点播课程", "面授课程"],
      titlesOne: ["简介", "作品", "我赞", "参展经历", "获奖经历", "出版经历", "拍卖记录"],
      titlesIndex: 0,
      produces: { data: [] },
      dbList: { data: [] },
      msList: { data: [] },
      zbList: { data: [] },
      page: 1,
      pageIndex: 1,
      pageSize: 4,
      total: 0,
      totalProd: 0,
      route: {}
    };
  },
  components: {
    noData
  },
  created () {
  },
  mounted () {
    this.getQuerySupplierById()
    this.getQueryNewestArtExhibition()
  },
  methods: {
    handleSizeChangeProd (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChangeProd (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getQueryNewestArtExhibition()
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      this.getQueryMyCollectArt(this.teacherInfo.StudentId)
    },
    titlesClickOne (i) {
      this.titlesIndex = i;
      let id = ""
      if (i == 0) {
        id = "introduction"
      } if (i == 1) {
        id = "scrollProd"
      } else if (i == 2) {
        id = "iLikeIt"
      } else if (i == 3) {
        id = "exhibitionExperience"
      } else if (i == 4) {
        id = "awardWinningExperience"
      } else if (i == 5) {
        id = "publishingExperience"
      } else if (i == 6) {
        id = "auctionRecord"
      }
      document.getElementById(id).scrollIntoView()
    },
    // 收藏
    async getQueryMyCollectArt (id) {
      let parm = {
        studentId: id,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      const res = await queryMyCollectArt(parm);
      if (res.data.success == true) {
        this.recList = res.data.response.data
        this.total = res.data.response.dataCount
      }
    },
    // 艺术家详情
    async getQuerySupplierById () {
      let parm = "?id=" + this.$route.query.id
      const res = await querySupplierById(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
        this.getQueryMyCollectArt(this.teacherInfo.StudentId)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 艺术家作品
    async getQueryNewestArtExhibition () {
      let parm = {
        ArtistId: this.$route.query.id,
        pageIndex: this.page,
        pageSize: this.pageSize,
      }
      const res = await queryNewestArtExhibition(parm);
      if (res.success == true) {
        this.artList = res.response.data
        this.totalProd = res.response.dataCount
      }
    },
    goToProd (obj) {
      let ob = {
        Id: obj.Id,
      };
      this.$router.push({ path: "/teacher/teacherWorksDetails", query: ob, type: this.route.type });
    },
    goTo (obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      this.$router.push({ path: "/course/details", query: ob });
    },
    titlesClick (i, idv) {
      this.titlesIndex = i;
      let id = ''
      if (i == 0) {
        id = "introduction"
      } if (i == 1) {
        id = "scrollProd"
      }
      document.getElementById(id).scrollIntoView()
    },
    // 作品列表
    async getCommonQueryTeacherProduce () {
      let parm = "?teacherId=" + this.$route.query.id
      const res = await commonQueryTeacherProduce(parm);
      if (res.success == true) {
        this.produces = res.response
      } else {
        this.$message.error(res.msg);
      }
    },
    // 课程
    async getCourseQueryCourseList (type) {
      let parm = {}
      parm.courseType = type // 	课程类型（0直播1点播2面授）
      parm.TeacherName = this.teacherInfo.Name
      parm.pageIndex = this.page
      parm.pageSize = this.pageSize
      const res = await courseQueryCourseList(parm);
      if (res.success == true) {
        if (type == 0) {
          this.zbList = res.response
        } else if (type == 1) {
          this.dbList = res.response
        } else if (type == 2) {
          this.msList = res.response
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 教师信息
    async getQueryTeacherInfo () {
      let parm = {}
      parm.teacherId = this.$route.query.id
      const res = await queryTeacherInfo(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
        this.getCourseQueryCourseList(1)
        this.getCourseQueryCourseList(2)
        this.getCourseQueryCourseList(0)
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.descript {
  padding: 28px 20px;
  line-height: 28px;
  color: #555;
  background-color: #fff;
  img {
    max-width: 100%;
  }
}
.teacherInformation {
  .teacher_contop {
    min-height: 500px;
    z-index: 0;
    background: #fff url(../../assets/images/personal_banner_bg.png) no-repeat
      100% 0;
  }
  .teacherpic_box {
    // display: flex;
    // align-items: center;
    // margin-top: -390px;
    padding-top: 100px;
    text-align: center;
    .headImg {
      width: 220px;
      height: 220px;
      border-radius: 100%;
      margin: 0 auto;
      position: relative;
      .headImg {
        border: 6px solid #fff;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #fff;
        // display: block;
        overflow: hidden;
        text-align: center;
        // margin-left: 40px;
        // margin-right: 25px;
      }
      .edit {
        position: absolute;
        top: 5px;
        right: -50px;
        i {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    .teacherinfo {
      .icon_qian {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
      p {
        &:nth-child(1) {
          font-size: 30px;
          line-height: 1;
          color: #333333;
          text-align: center;
          margin: 20px 0;
        }
        &:nth-child(2) {
          font-size: 14px;
          line-height: 1.5;
          color: #333333;
          width: 750px;
          margin: 0 auto;
        }
      }
      .descript {
        width: 825px;
        line-height: 1.5;
        font-size: 14px;
        color: #333333;
        margin: 0 auto;
        padding: 22px 0;
      }
    }
    .auth {
      text-align: center;
      line-height: 1;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 85px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #fff;
        background: #3a78f9;
        border-radius: 20px;
        &::before {
          content: "";
          display: inline-block;
          width: 13.5px;
          height: 14px;
          margin-right: 3px;
          vertical-align: text-bottom;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/icon_renzhen.png");
        }
      }
    }
  }
  .introduce {
    background: #fff;
    margin-top: 35px;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: #111;
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
      border-bottom: 1px solid #eee;
    }
  }

  .cont {
    .cont_top {
      height: 50px;
      background: #ffffff;
      span,
      p {
        display: inline-block;
        margin-right: 30px;
        line-height: 50px;
        font-size: 16px;
        cursor: pointer;
      }
      p {
        span {
          margin: 0 auto;
        }
      }
      .active {
        color: #1288f4;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #1288f4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .cont_lists {
      min-height: 200px;
      margin-bottom: 20px;
      .cont_li {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-bottom: 10px;
          margin-right: 26px;
          // flex: 0 0 280px;
          position: relative;
          border: 1px solid #e7e7e7;
          background-color: #fff;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .coverImg {
            width: 278px;
            height: 278px;
          }
          .count {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #2b6efb;
            padding: 10px;
            font-size: 13px;
            color: #fff;
          }

          .title2 {
            padding: 0 20px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            margin-bottom: 14px;
            margin: 18px 0;
          }
          .default {
            font-size: 15px;
            font-weight: bold;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            span {
              font-size: 12px;
            }
            .defaultPrice {
              color: #fe4a4a;
              font-weight: bold;
            }
            .icon_heart {
              width: 12px;
            }
          }
          .perPrice {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;
            color: #666666;
            .per {
              .head {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin: 0;
              }
              span {
                font-size: 14px;
                margin: 0 5px;
              }
            }
            .price {
              font-size: 22px;
              font-weight: bold;
              color: #fe4a4a;
              span {
                font-size: 0.625rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .cont_li2 {
        .coverImg1 {
          width: 100%;
          height: 160px;
        }
      }
    }
  }
}
/deep/.el-pagination {
  text-align: right;
}
</style>